<template>
  <div class="rightAlert">
    <div class="rightItem" @click="goToSetTalk">
      <al-image :src="custom1" class="img" fit="scale-down"></al-image>
      My Meeting
    </div>
    <!-- <div class="rightItem" @click="goToTalk">
      <al-image :src="custom" class="img" fit="scale-down"></al-image>
      Custom Service
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      custom:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rightIcon/6.png",
      custom1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rightIcon/7.png",
    };
  },
  methods: {
    goToSetTalk() {
      this.$router.push("/console/conference/myReserved");
    },
    goToTalk() {
      window.open(
        "https://tb.53kf.com/code/client/7a4b8f1a2d84ba6cde165448d5d24c391/9"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.rightAlert {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
  z-index: 2001;
}
/deep/ .el-image {
  background: #fff !important;
}
.rightItem {
  width: 170px;
  height: 50px;
  background: #feffff;
  overflow: hidden;
  box-shadow: -3px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px 0px 0px 8px;
  z-index: 11;
  margin-bottom: 15px;
  //   right: 0px;
  //   top: 400px;
  //   position: fixed;
  display: flex;
  color: #999999;
  cursor: pointer;
  display: flex;
  align-items: center;

  .img {
    height: 25px;
    width: 25px;
    margin: 0 10px 0px 15px;
  }
}
</style>
