<template>
  <div>
    <navMenu></navMenu>
    <div class="page_content">
      <router-view></router-view>
    </div>
		<div class="rightAlert"><rightAlert></rightAlert></div>
    <pageFooter></pageFooter>
  </div>
</template>
<script>
import navMenu from "~nbk/components/layouts/navMenu";
import pageFooter from "~nbk/components/layouts/pageFooter";
import rightAlert from "~nbk/components/layouts/rightAlert"
export default {
  components: { navMenu, pageFooter,rightAlert },
};
</script>
<style lang="less" scoped>
.page_content {
  min-height: calc(100vh - 368px);
}
</style>