import Vue from 'vue'
Vue.use(VueRouter)
import { baseConsoleRoutes } from '~bac/router'
export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',                                              //首页
      name: 'index',
      component: resolve => require(['~nbk/views/index'], resolve),
    },
    {
      path: '/companyList',                                              //首页
      name: 'companyList',
      component: resolve => require(['~nbk/views/companyList'], resolve),
    },
    {
      path: '/userList',                                              //首页
      name: 'userList',
      component: resolve => require(['~nbk/views/userList'], resolve),
    },
    {
      path: '/sign',                                              //登录
      name: 'sign',
      component: resolve => require(['~nbk/views/users/sign'], resolve),
      meta: {
        layout: 'sign'
      }
    }, {
      path: '/signUp',                                              //注册
      name: 'signUp',
      component: resolve => require(['~nbk/views/users/signUp'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/forgetPassword',                                              //忘记密码
      name: 'forgetPassword',
      component: resolve => require(['~nbk/views/users/forgetPassword'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/resetPassword',                                              //忘记密码
      name: 'resetPassword',
      component: resolve => require(['~nbk/views/users/resetPassword'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/handleInitialParams',                                              //中转页面
      name: 'handleInitialParams',
      component: resolve => require(['~nbk/views/users/initPage'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/register',                                              //报名页面
      name: 'register',
      component: resolve => require(['~nbk/views/register/index'], resolve),
      meta: {
        layout: 'sign'
      }
    }, {
      path: '/registerSuccess',                                              //报名页面
      name: 'registerSuccess',
      component: resolve => require(['~nbk/views/register/success'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/agreement',                                              //登录
      name: 'agreement',
      component: resolve => require(['~nbk/views/agreement/index'], resolve),
      meta: {
        layout: 'sign'
      }
    }, {
      path: '/helpCenter',
      name: 'helpCenter',
      component: resolve => require(['~nbk/views/sopCenter'], resolve)
    },
  ].concat(baseConsoleRoutes).concat([{
    path: "**",
    redirect:"/"
  }]),
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
