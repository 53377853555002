<template>
  <div class="navMenuComponents">
    <div class="pageHeader">
      <div class="content_area">
        <!-- <router-link to="/">
          <img :src="url" alt class="logo" />
        </router-link> -->

        <p class="timeStart">
          2022 China (Ningbo) Export Cross-border E-commerce Expo
          <!-- 2021年05月24日-29日(GMT+08)，Countdown {{ time }} days -->
        </p>
        <div class="userLogin">
          <div class="loin" v-if="!IS_LOGIN">
            <router-link to="/sign">Log In</router-link>
            <el-divider direction="vertical"></el-divider>
            <router-link to="/signUp">Sign Up</router-link>
          </div>
          <div v-else>
            <el-popover
              class="fl"
              popper-class="userInfoPopover"
              placement="bottom"
              width="200"
              trigger="hover"
            >
              <div class="infoContent">
                <div class="imageAndname clearfix">
                  <img
                    :src="USER_INFO.avatar || DEFAULT_AVATAR"
                    class="userAvatar_inner fl"
                    fit="sacle-down"
                  />
                  <div class="fl textOverflow">
                    {{
                      USER_INFO.name_en | priorFormat(USER_INFO.name_zh, LOCALE)
                    }}
                  </div>
                </div>
                <div class="pagelist">
                  <div
                    v-for="(item, key) in pageList"
                    :key="key"
                    class="item"
                    @click="goToPage(item.url)"
                  >
                    <i :class="item.icon"></i>{{ $t(item.label) }}
                  </div>
                </div>
                <div class="logout" @click="logOut">
                  {{ $t("logout") }}
                </div>
              </div>
              <div slot="reference">
                <span class="cursor user_info">
                  <img
                    :src="USER_INFO.avatar || DEFAULT_AVATAR"
                    class="userAvatar"
                    fit="sacle-down"
                  />
                </span>
              </div>
            </el-popover>
            <el-button
              type="text"
              style="color: #fff"
              @click="$router.push('/console/account/userInfo')"
              >{{ $t("console") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="navMenu">
      <div class="content_area">
        <el-menu
          class="el-menu-exp"
          :class="LOCALE"
          mode="horizontal"
          :default-active="current"
          menu-trigger="click"
          :unique-opened="true"
          :router="true"
          @select="selectMenu"
          active-text-color="#027fff"
        >
          <el-menu-item index="/">{{ $t("index") }}</el-menu-item>
          <el-divider class="fl divider" direction="vertical"></el-divider>
          <el-menu-item index="/userList">{{$t('meeting1v1')}}</el-menu-item>
          <el-divider class="fl divider" direction="vertical"></el-divider>
          <el-menu-item index="/companyList">{{$t('exhibitorList1')}}</el-menu-item>
          <el-divider class="fl divider" direction="vertical"></el-divider>
          <el-menu-item index="/helpCenter">Help Center</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  data() {
    return {
      url:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/logo.png",
      current: "/",
      time: null,
      dialogVisible: false,
      pageList: [
        {
          url: "/console/account/userInfo",
          label: "console",
          icon: "el-icon-monitor",
        },
        // {
        //   url: "/console/conference/caseCard",
        //   label: "myCard",
        //   icon: "el-icon-postcard",
        // },
        {
          url: "/console/account/security",
          label: "AccountSettings",
          icon: "el-icon-setting",
        },
      ],
    };
  },
  watch: {
    // $route:{
    //   deep: true,
    //   handler(nv){
    //     this.handleRouteInfo(nv)
    //   }
    // }
    $route(nv) {
      this.handleRouteInfo(nv.path);
    },
  },

  mounted() {
    let m1 = this.$moment("2021-05-24"),
      m2 = this.$moment();
    let res = m1.diff(m2, "day");
    this.time = res;
  },
  methods: {
    async logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");

      this.$router.push("/sign");
    },
    goToPage(url) {
      this.$router.push(url);
    },
    handleMenuCommand(command) {
      if (command == "logOut") {
        this.logOut();
        return;
      } else {
        this.$router.push(command);
      }
    },
    selectMenu(index, path) {
      if (index == "") {
        this.dialogVisible = true;
      }
    },
    handleRouteInfo(path) {
      this.current = path;
    },
  },
};
</script>

<style scoped lang="less">
.navMenuComponents {
  width: 100%;
  .pageHeader {
    width: 100%;
    height: 62px;
    background: #027fff;
    .content_area {
      position: relative;
      height: 62px;
      display: flex;
      align-items: center;
      .logo {
        width: 101px;
      }
      .timeStart {
        flex: 1;
        text-align: center;
        font-size: 21px;
        font-weight: 600;
        color: #aed7ff;
        span {
          color: #fff;
        }
      }
      .loin {
        width: 120px;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        a {
          color: #ffffff;
        }
      }
      .language {
        width: 60px;
        /deep/ .el-dropdown {
          span {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
  .navMenu {
    width: 100%;
    height: 50px;
    background: #ffffff;
    text-align: center;
    .content_area {
      // display: flex;
      // justify-content: center;
      //border-bottom: 1px solid #eee;
      height: 50px;
      line-height: 50px;
    }
    .el-menu-exp {
      //border-bottom: 0 none;
    }
    .en .el-menu-item {
      padding: 0 16px;
    }
    /deep/ .el-menu--horizontal > .el-menu-item {
      height: 50px;
      line-height: 50px;
      color: #a8acb8;
    }
    /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 50px;
      line-height: 50px;
      color: #a8acb8;
    }
    /deep/ .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 0;
      background-color: #fff !important;
    }
    /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
      border-bottom: 0;
    }
    /deep/ .el-menu-item {
      border-bottom-color: #fff !important;
    }
    /deep/ .el-submenu__title {
      border-bottom-color: #fff !important;
    }
    .divider {
      top: 12px;
      background: linear-gradient(
        180deg,
        rgba(232, 232, 232, 0) 0%,
        #dedede 49%,
        rgba(135, 139, 141, 0) 100%
      );
      height: 2em;
    }
    /deep/ .el-submenu__title i.el-submenu__icon-arrow {
      color: #909399 !important;
    }
  }
}

.userAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.userLogin {
  margin: 0 20px !important;
  height: 62px;
  line-height: 62px;
}
.openDialog {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.userInfoPopover {
  padding: 0px !important;
  .infoContent {
    .imageAndname {
      padding: 10px;
      padding-bottom: 8px;
      border-bottom: 1px solid #eee;
      .userAvatar_inner {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .textOverflow {
        width: 130px;
        line-height: 35px;
      }
    }
    .pagelist {
      border-bottom: 1px solid #eee;
      padding: 5px 0px;
      .item {
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        padding-left: 10px;
        i {
          margin-right: 8px;
          font-size: 16px;
          position: relative;
          top: 1px;
          // color: #027fff;
        }
        &:hover {
          cursor: pointer;
          background-color: #e6f2ff;
          color: #027fff;
        }
      }
    }
    .logout {
      line-height: 34px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      color: #f56c6c;
      &:hover {
        background: #f4f6f7;
      }
    }
  }
}
</style>
